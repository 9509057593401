/***************************************
  Asset Field
***************************************/

.asset-field {
  margin-right: 1rem;
  width: 105px;
  height: 110px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .asset-field-card {
    margin-bottom: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    figcaption {
      color: $lf-gray-dark;
      font-size: 1.2rem;
      margin-bottom: 0.3rem;
    }
  }
}
