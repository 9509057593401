/***************************************
  Form Grid
***************************************/

.content-wrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.asset-fields-wrapper {
  .asset-field-container {
    margin-top: 5rem;
  }
}

.asset-field-container {
  align-items: center;
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;

  > .form-group {
    margin-top: 0.5rem;
    width: 100%;
  }
}

.form-group-inline {
  align-items: flex-start;
  display: flex;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  &.row {
    > [class*='col'] {
      padding-left: 0;
    }
  }

  &:not(.row) {
    > * {
      margin-left: 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.form-drag-handler {
  width: 20px;
  margin-right: 1rem;
}

.form-actions {
  margin-top: 5rem;
}

.row-action-btn {
  button {
    margin-left: 0.625rem;

    &:first-child {
      margin-left: 0;
    }
  }

  .action-btn-pull-5-5 {
    @include media-breakpoint-up(lg) {
      margin-left: -5.5rem;
    }
  }
}

.form-inline {
  display: flex;

  > * {
    &:not(:first-child) {
      margin-left: 2rem;
    }
  }
}

.row-added-product {
  margin-top: 3rem;

  &:first-child {
    margin-top: 0;
  }

  > * {
    width: 100%;
  }
}

.row-col-3 {
  > [class*='col'] {
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      &:nth-child(-n + 3) {
        margin-top: 0;
      }

      &:nth-child(3n + 1) {
        padding-left: 0;
        padding-right: 15px;
      }
    }
  }
}

.fonts-form {
  .row-action-btn {
    justify-content: center;
  }
}

.fabric-swatches-form {
  .Polaris-Card__Section {
    > .form {
      &:not(:first-child) {
        margin-top: 1.8rem;
      }

      .invalid-feedback {
        position: absolute;
      }
    }
  }
}

.form-product {
  [class*='mt-'] {
    .form-group-inline {
      &:not(:first-child) {
        margin-top: 1.8rem;
      }

      .invalid-feedback {
        position: absolute;
      }
    }
  }
}

.form-text-embroidery-module {
  .form-inner {
    .container {
      > .form-items-row {
        &:not(:first-child) {
          margin-top: 1.8rem;
        }

        .invalid-feedback {
          position: absolute;
        }
      }
    }
  }
}

.row-inner-variant {
  .row-action-btn {
    justify-content: flex-end;
  }
}

.status-selector {
  &.status-selector-sticky {
    position: sticky;
    top: 80px;
  }
}
