/***************************************
  Customizer Moveable
***************************************/

.customizer-moveable-option {
  align-items: center;
  cursor: default;
  justify-content: center;
  display: inline-flex;
  position: absolute;
  transform: translateZ(0);

  > * {
    transform: translateZ(0);
  }

  .customizer-moveable-option-inner {
    position: relative;
    outline: 1px solid transparent;
    transition: all 0.5s ease 0s;
    transition-property: opacity, border-color, background-color, outline-color;
    width: 100%;
    height: 100%;
  }

  .customizer-option {
    position: relative;
    width: 100%;
    height: 100%;
    transition: outline-color 0.5s ease 0s;
  }

  &:focus,
  &:active,
  &:hover {
    &:not(.is-zoomed):not(.is-selected) {
      .customizer-moveable-option-inner {
        outline-color: $lf-white;

        .customizer-option {
          outline: 1px solid $lf-stone-gray;
          outline-offset: 1px;
        }

        .customizer-knob {
          opacity: 1;
        }
      }
    }
  }

  &.is-invalid {
    background-color: $red-tint;
    outline: 1px dashed $red-higher;
  }

  img {
    outline: 1px solid transparent;
    transition: outline 0.5s ease 0s;
  }

  &.is-selected {
    > .customizer-moveable-option-inner {
      outline-color: $lf-white;

      .customizer-option {
        outline: 1px solid $lf-stone-gray;
        outline-offset: 1px;
      }
    }

    .customizer-knob {
      opacity: 1;
    }

    img {
      outline: 1px solid $lf-white;

      @at-root .is-zoomed & {
        outline-color: transparent;
      }
    }
  }

  .customizer-knob {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: $lf-stone-gray;
    border: 1px solid $lf-white;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.1s ease 0s;
  }

  &.is-zoomed {
    .customizer-option {
      outline-color: transparent;
    }

    .customizer-knob {
      opacity: 0;
    }

    img {
      outline-color: transparent;
    }

    .customizer-moveable-option-inner {
      outline-color: transparent;
    }

    &.is-selected {
      .customizer-option {
        outline-color: transparent;
      }
    }
  }

  .ne,
  .nw {
    top: -6px;
  }

  .se,
  .sw {
    bottom: -6px;
  }

  .ne,
  .se {
    right: -6px;
  }

  .nw,
  .sw {
    left: -6px;
  }

  .customizer-option-patch,
  .customizer-option-symbol {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .customizer-option-text {
    white-space: break-spaces;
    z-index: 2;

    svg {
      display: block;
      fill: currentColor;
      width: 100%;
      height: 100%;
    }

    p {
      margin: 0;
      line-height: 0.97;
      user-select: none;
    }

    .customizer-option-text-overlay {
      position: absolute;
      top: 0;
      text-align: center;
      left: -0.5px;
      width: auto;
      z-index: 3;
    }
  }

  .customizer-option-symbol {
    .preview-symbol-text {
      font-family: $block-base;
      font-size: 1.8rem;
      top: 50%;
      left: 56%;
      transform: translateX(-50%) translateY(-50%);
      text-transform: uppercase;
      margin: 0;
      position: absolute;
      padding-right: 11px;
      user-select: none;
      white-space: pre;

      @include media-breakpoint-up(sm) {
        left: 50%;
      }
    }

    &.preview-symbol-mamalove {
      .preview-symbol-text {
        font-size: 1.8rem;
        padding-top: 0.3rem;
        padding-left: 0.5rem;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
          font-size: 3.5rem;
        }
      }
    }

    &.preview-symbol-sweetheart_xx {
      .preview-symbol-text {
        font-size: 1.2rem;
        font-weight: bold;

        @include media-breakpoint-up(md) {
          font-size: 1.7rem;
        }

        @include media-breakpoint-up(md) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.customizer-moveable {
  &.moveable-control-box {
    .customizer-moveable-options {
      background-color: $lf-white;
      box-shadow: 0 0 6px rgba(18, 18, 18, 0.22);
      border-radius: 30px;
      transform: translate(-50%, 0);
      transition: opacity 0.2s ease;
      top: -55px;
      will-change: transform;
      white-space: nowrap;
      padding: 0.2rem;
      position: absolute;
      opacity: 1;

      .customizer-moveable-rotate-slider {
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 0 6px rgba(18, 18, 18, 0.22);
        border-radius: 40px;
        display: flex;
        padding: 20px 16px;
        position: absolute;

        .customizer-moveable-slider {
          appearance: none;
          height: 1px;
          background-color: $lf-black;
          outline: none;
          width: 100%;

          &::-moz-range-thumb {
            appearance: none;
            width: 15px;
            height: 15px;
            background-color: $peach;
            border: 1px solid $lf-black;
            border-radius: 50%;
            cursor: pointer;
          }

          &::-webkit-slider-thumb {
            appearance: none;
            width: 15px;
            height: 15px;
            background-color: $peach;
            border: 1px solid $lf-black;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
    }

    .moveable-control {
      &.moveable-rotation-control {
        border-radius: 10px;
        width: 15px;
        height: 15px;
        margin-left: -7.5px;
      }
    }
  }

  .moveable-rotation {
    height: 40px;
  }

  .moveable-line {
    border: 0;
    background: transparent !important;
    width: 1px;
    height: 1px;
    cursor: default !important;
    top: -1px !important;
    left: -1px !important;
  }

  .moveable-rotation-line {
    position: relative;
    background: $lf-white !important;
    box-shadow: 0 -1px 0 $lf-stone-gray;

    &:after {
      position: absolute;
      content: '';
      top: -1px;
      bottom: 0;
      width: 1px;
      background-color: $lf-stone-gray;
      left: 1px;
    }
  }

  .moveable-rotation-control {
    border-color: $lf-stone-gray !important;
  }
}
