/***************************************
  Customizer Pane Overlay
***************************************/

.customizer-pane-overlay {
  background-color: transparent;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 10;

  &.overlay-open {
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 0.5s;
  }

  &.overlay-close {
    background-color: rgba(0, 0, 0, 0);
  }
}
