/***************************************
  Customizer Editor
***************************************/

.customizer-editor {
  &.customizer-editor-desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      @include make-col(5);
      display: block;
    }
  }

  &.customizer-editor-mobile {
    background-color: $lf-white;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100px;
    transform: translateX(100%);
    transition: transform 0.5s;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    will-change: transform;
    width: 100%;
    z-index: 100;

    &.customizer-pane-open {
      transform: translateX(0);

      .customizer-editor-header {
        background-color: $lf-white;
        position: sticky;
        top: 0;
        z-index: 6;
      }
    }
  }

  .customizer-editor-header {
    @include flex-center;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      box-shadow: 0 1px 0 1px $lf-gray-light;
      padding: 0.625rem 1.625rem;
    }

    .customizer-editor-tabs {
      @include flex-center;
      border-bottom: 1px solid $dark-gray;
      flex-wrap: wrap;
      list-style: none;
      margin: 2.5rem 0 0;
      padding: 0;
      text-align: center;
      letter-spacing: -0.2px;
      width: 100%;

      li {
        border-bottom: 4px solid transparent;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        width: 100%;

        &.active {
          border-bottom-color: $lf-black;

          .btn-common-tab {
            color: $lf-black;
            font-weight: 700;
          }
        }

        .btn-common-tab {
          color: $lf-gray;
          font-size: 1.125rem;
          padding-bottom: 0.625rem;
          width: 100%;

          &:hover {
            color: $lf-black;
          }

          &:focus,
          &:focus-visible {
            box-shadow: none;
            outline: 0;
          }
        }
      }
    }
  }

  .customizer-editor-modules {
    padding-bottom: $r7;

    @include media-breakpoint-up(md) {
      padding-bottom: 9.375rem;
    }

    .customizer-editor-modules-wrapper {
      position: relative;

      .customizer-tab-inactive {
        display: none;
        position: relative;
        z-index: 1;
      }

      .customizer-module {
        padding: $r4 $r4 0 $r4;

        &.is-sticky {
          box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
        }

        &.customizer-editor-preview {
          background-color: $lf-white;
          position: sticky;
          top: 62px; // height of mobile header
          transition: all 0.3s ease-in-out;
          z-index: 5;

          @include media-breakpoint-up(lg) {
            top: -1px;
          }

          &.customizer-module-symbols,
          &.customizer-module-patches {
            padding-bottom: $r2;
          }
        }
      }
    }
  }
}
