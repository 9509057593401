/***************************************
  Colors
***************************************/

$lf-black: #121212;
$lf-white: #fff;
$dark-gray: #949494;
$lf-gray: #737373;
$lf-gray-light: #e7e7e7;
$lf-gray-muted: #cecece;
$moveable-hover: rgb(121, 179, 255);
$light-peach: #fff5ed;
$peach: #fde4d1;
$red-tint: rgba(255, 191, 191, 0.5);
$red-higher: #ff0000;
$lf-stone-gray: #6b6b6b;
