/***************************************
  Collapsible
***************************************/

.collapsible {
  .collapsible-header {
    display: flex;
    justify-content: space-between;

    .collapsible-header-content {
      display: flex;
      align-items: center;

      > * {
        margin-left: 1rem;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .collapsible-content {
    padding-top: 2rem;
    border-top: 1px solid #ddd;
    margin-top: 3rem;
  }
}
