/***************************************
  Buttons
***************************************/

.btn {
  transition: all 0.1s linear;

  &.btn-wide {
    width: 100%;
  }

  &.btn-action {
    padding: 0;
    border-radius: 0;

    &:focus,
    &:focus-visible {
      box-shadow: none;
      outline: 0;
    }

    &.btn-action-label {
      @include flex-center;
      flex-direction: column;

      span {
        color: $lf-black;
        font-size: 1rem;
        margin-top: 0.3125rem;

        @include media-breakpoint-up(sm) {
          font-size: 1.25rem;
        }
      }
    }

    &.btn-common-zoom {
      width: 60px;

      @include media-breakpoint-up(sm) {
        width: 88px;
      }
    }

    &.btn-common-zoom,
    &.btn-common-restart {
      .icon {
        width: 26px;

        @include media-breakpoint-up(sm) {
          width: 32px;
        }
      }
    }
  }

  &.btn-common {
    border: 1px solid $lf-black;
    border-radius: 0;
    font-size: 1.125rem;
    padding: 0.656rem;
    position: relative;

    &:after {
      bottom: 3px;
      border: solid 1px $lf-black;
      content: '';
      display: block;
      left: 3px;
      position: absolute;
      opacity: 0;
      right: 3px;
      top: 3px;
      transition: all 0.1s linear;
      z-index: 1;
    }

    &:hover,
    &:active {
      &:after {
        opacity: 1;
      }
    }

    &:not(:disabled) {
      &:hover {
        background-color: $light-peach;
      }
    }

    &:not(:disabled) {
      &:active {
        background-color: $peach;
      }
    }

    &:not(:disabled) {
      &:focus-visible {
        &:not(:active),
        &:not(:hover) {
          background-color: $peach;
          border: 1px solid $lf-black;
        }
      }
    }

    &:disabled,
    &.disabled {
      color: $lf-gray;
      border-color: $lf-gray;

      &:after {
        opacity: 0;
      }
    }

    ~ .btn-common {
      margin-left: $r1;
    }

    &.btn-common-inverse {
      color: $lf-white;
      background-color: $lf-black;

      &:hover,
      &:active {
        color: $lf-white;
        background-color: $lf-black;
      }

      &:hover,
      &:focus,
      &:active {
        &:after {
          opacity: 1;
        }
      }

      &:active {
        color: $peach;

        &:after {
          border-color: $peach;
        }
      }

      &:focus-visible {
        &:not(:hover) {
          background-color: $lf-black;

          &:after {
            border-color: $peach;
          }
        }
      }

      &:after {
        border-color: $lf-white;
      }

      &.btn-common-selected {
        color: $peach;

        &:after {
          border-color: $peach;
        }
      }

      &:disabled,
      &.disabled {
        color: $lf-white;
        background-color: $lf-gray;
        border-color: $lf-gray;

        &:after {
          opacity: 0;
        }
      }
    }

    &.btn-common-selected {
      background-color: $peach;

      &:after {
        opacity: 1;
      }
    }

    &.btn-font-size,
    &.btn-font-style,
    &.btn-font-variant {
      text-transform: lowercase;
      line-height: 1;
      position: relative;

      .font-style-base {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.btn-font-size {
      flex: 1;
      font-size: 5rem;
      padding: 0;
      line-height: 1;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &.btn-font-style {
      font-size: 2.2rem;
      padding: 1.5rem 1.125rem 1.125rem 1.125rem;
      height: 100%;
    }

    &.btn-font-variant {
      font-size: 5rem;
      padding: 0;
    }

    &.btn-graphic-option {
      min-width: 100px;
      min-height: 100px;
      position: relative;

      // nextjs image component

      > div {
        margin: 0.75rem !important;
      }

      &.btn-common-selected {
        .icon-symbol-custom {
          display: none;
        }
      }

      .patch-status {
        background-color: rgba(241, 241, 241, 0.8);
        color: $lf-black;
        font-size: 1rem;
        position: absolute;
        padding: 0.25rem 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 4;
      }

      .patch-variant-size {
        @include flex-center;
        background-color: $lf-black;
        bottom: 0;
        color: $lf-white;
        font-size: 1rem;
        line-height: 1;
        position: absolute;
        right: 0;
        width: 25px;
        height: 25px;
      }
    }

    &.btn-add-text {
      .icon-plus {
        margin-left: 5px;
      }
    }

    &.btn-centered {
      @include flex-center;
    }

    .icon-symbol-custom {
      bottom: 0px;
      right: -1px;
      position: absolute;
      z-index: 2;
    }
  }

  &.btn-thread-option {
    &.btn-selected {
      border: 2px solid $lf-black;
      padding: 2px;
    }
  }

  &.btn-action-close {
    &-desktop {
      display: none;
      position: absolute;
      top: 1.625rem;
      left: 1.625rem;
      z-index: 10;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &-absolute {
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
  }

  &.btn-close-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.9375rem;
    z-index: 10;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &.btn-common-tab {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  &.btn-moveable-editor {
    user-select: auto;
    border-radius: 50%;
    background-color: transparent;
    transition: all 0.2s;
    padding: 5px;

    &:hover,
    &:active {
      &:after {
        opacity: 1;
      }
    }

    &:not(:disabled) {
      &:hover {
        background-color: $light-peach;
      }
    }

    &:not(:disabled) {
      &:active {
        background-color: $peach;
      }
    }

    &:not(:disabled) {
      &:focus-visible {
        &:not(:active),
        &:not(:hover) {
          background-color: $peach;
          border: 1px solid $lf-black;
        }
      }
    }

    &:disabled,
    &.disabled {
      color: $lf-gray;
      border-color: $lf-gray;

      &:after {
        opacity: 0;
      }
    }

    .icon {
      height: 28px;
      width: 28px;
      pointer-events: none;
    }
  }
}
