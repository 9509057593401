/***************************************
  Colors
***************************************/

$lf-gray-dark: #6d7175;
$lf-blue-dark: #4680d1;
$lf-white: #fff;
$lf-gray: #737373;
$lf-light-gray: #d8dbde;
$lf-lighter-gray: #f6f6f7;
$lf-muted-gray: #8c9196;
$lf-gray-darker: #666;
$lf-error-backdrop: rgba(255, 244, 244, 1);
$lf-error-border: rgba(253, 87, 73, 1);
$border-color: #e1e3e5;
$gray-dark: #6d7175;
$blue-dark: #4680d1;
$disabled-state: #fafbfb;
$sporange: #bf552b;
$lf-black: #121212;
$critical-red: #d72c0d;
