/***************************************
  Forms
***************************************/

.form {
  width: 100%;

  > * + * {
    margin-top: 1.5rem;
  }
}

label {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.8rem;

  &.label-inline {
    color: $lf-blue-dark;
    display: inline-block;
    cursor: pointer;
  }
}

.form-control {
  font-size: 1.4rem;
  line-height: 2.4rem;
  border-color: rgba(201, 204, 207, 1);
  border-top-color: rgba(174, 180, 185, 1);
  border-radius: 0.35rem;
  height: calc(1.893em + 0.75rem + 2px);
  width: 100%;

  &:disabled,
  &:read-only,
  &.form-control-disabled-light {
    color: $lf-muted-gray;
    background-color: rgba(250, 251, 251, 1);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 143, 255, 1);
  }
}

.form-group {
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;

  // polaris changes

  .Polaris-Labelled__LabelWrapper {
    margin-bottom: 0;
  }

  .Polaris-Labelled__Error {
    margin-top: 0;
    position: relative;

    .Polaris-InlineError {
      position: absolute;
      top: 0.4rem;
    }
  }
}

.form-preview {
  font-size: 4.5rem;
  border-radius: 8px;
  border: 1px dashed $lf-muted-gray;
  height: 5.625rem;
  align-items: center;
  display: flex;
  padding: 5rem 3rem;

  p {
    margin: 0;
  }

  &.font-preview-disabled {
    background-color: $disabled-state;
    cursor: not-allowed;
    color: #888;
  }
}

.form-staggered {
  @include media-breakpoint-up(lg) {
    @include make-col(11);
  }

  &-right {
    @include media-breakpoint-up(lg) {
      margin-left: auto;
    }
  }
}

.form-border {
  border: 1px solid $border-color;
  padding: 2rem;

  &-top {
    border-top: 1px solid $border-color;
    padding-top: 1.875rem;
    margin-top: 1.875rem;
  }
}

.form-upload {
  // polaris changes

  .Polaris-DropZone {
    min-height: 0;
    margin-top: 1rem;

    .Polaris-DropZone__Container {
      position: static;
    }
  }
}

.form-item,
.form-graphic,
.form-dropzone {
  margin-left: 2rem;
}

.form-graphic {
  .Polaris-InlineError {
    margin: 4rem 1rem;
    position: absolute;
  }
}

.form-items {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.form-items-column {
    flex-direction: column;
  }

  // polaris changes

  .Polaris-Choice {
    align-items: center;

    .Polaris-Choice__Control {
      margin-right: 1.5rem;
    }
  }
}

.form-item-row {
  display: flex;
  gap: 1.25rem;

  .row {
    margin-top: 0;

    > [class*='col'] {
      padding-right: 5px;

      &:not(:first-child) {
        padding-left: 5px;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .form-item-column {
    width: 240px;

    .card-item {
      padding: 0;
    }
  }
}

.polaris-card-dark {
  .Polaris-Card {
    background-color: $lf-lighter-gray;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
  }
}

.form-perspective-item {
  margin-top: 1rem;

  .form-perspective-color-preview {
    @include flex-center;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    max-width: 80px;
    line-height: 1.5;

    .form-perspective-color {
      width: 15px;
      height: 15px;

      + .form-perspective-color-label {
        margin-left: 1rem;
      }
    }
  }
}

.form-items-row {
  display: flex;
  margin-top: 0.625rem;

  &:first-child {
    margin-top: 0;
  }
}

.form-items-column {
  &.form-column-12 {
    width: 100%;
  }
}

.form-section-title {
  .Polaris-Heading {
    font-size: inherit;
  }
}

.form-entries {
  .form-entry {
    &:not(:first-child) {
      border-top: 1px solid $border-color;
      margin-top: 1.875rem;
      padding-top: 1.875rem;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

.invalid-feedback {
  display: block;
}

.form-color-picker {
  flex-direction: column;
}

.form-preview-image {
  &.patch-preview-small {
    .Polaris-Thumbnail--sizeSmall {
      height: 36px;
    }
  }
}

.form-product [class*='mt-'] {
  .form-group-inline {
    &.form-group-patch-preview {
      .invalid-feedback {
        position: relative;
      }
    }
  }
}
