/***************************************
  Work Order Page
***************************************/

.container-work-order {
  @media print {
    max-width: 100%;
  }

  .work-order-logo {
    margin-bottom: 2rem;
  }

  .work-order-simple {
    border: 1px solid $lf-gray-dark;
    padding: 0.5rem;

    .work-order-row {
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid $lf-light-gray;

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: none;
      }
    }
  }

  .table {
    color: $lf-black;

    @media print {
      width: 100%;
      height: 100%;
    }

    &.table-work-order {
      margin-top: 2rem;

      .work-order-value {
        color: $lf-gray-darker;
      }

      .img-work-order {
        margin: 2rem 0;
        page-break-inside: avoid;

        @media print {
          width: 100%;
          height: auto;
        }
      }
    }

    @media print {
      tr,
      td,
      th {
        page-break-inside: avoid;
      }

      /* Repeat table headers when table spans a page */
      thead {
        display: table-header-group;
      }

      .print-hidden {
        visibility: hidden;
        display: none;
      }
    }
  }
}
