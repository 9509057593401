/***************************************
  Customizer Modules
***************************************/

.customizer-module {
  // options

  .editor-option {
    margin-top: $r6;

    &:first-child {
      margin-top: 0;
    }

    .editor-option-header {
      @include flex-center;
      justify-content: space-between;
      line-height: 1;
      margin-bottom: $r2;

      &.with-range {
        margin-bottom: 0;
      }
    }

    .editor-option-meta {
      @include flex-center;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: $r2;
    }

    .editor-option-price-range {
      font-size: 1.25rem;
      color: $lf-gray;
      margin-bottom: $r2;
    }

    .editor-thread-list {
      display: grid;
      gap: 0.4375rem;
      grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));

      .editor-thread-option {
        min-width: 60px;
        min-height: 60px;
        max-width: 80px;
        max-height: 80px;
      }
    }

    .editor-options-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

      .btn {
        ~ .btn {
          margin-left: 0;
        }
      }

      .editor-option-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: 100%;
      }

      &.editor-beading-options {
        .editor-option-item {
          flex-grow: 1;
          flex-basis: 0;
        }
      }

      &.editor-graphic-options {
        gap: $r1;

        .btn-graphic-option {
          &.btn-common-selected {
            .symbol-custom-options-icon {
              opacity: 0;
            }
          }
        }
      }
    }

    .editor-option-title {
      color: $lf-black;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
      letter-spacing: 2px;
    }

    .editor-option-subtitle {
      &.text-input-counter {
        font-size: 1.125rem;
        color: $dark-gray;
      }
    }

    // form fields

    .editor-option-textarea {
      position: relative;

      .customizer-textarea {
        position: relative;
        z-index: 4;

        &.customizer-textarea-overlay {
          background: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 3;
        }
      }
    }

    .input-field-label {
      color: $dark-gray;
      font-size: 1rem;
      margin-top: 0.625rem;
      padding-bottom: 0.2rem;
    }

    .editor-option-textarea {
      .editor-option-textarea-wrapper {
        background-color: $dark-gray;
        background-blend-mode: soft-light;
        min-height: 6.25rem;
        max-height: 15.625rem;
        overflow-y: auto;

        .customizer-textarea {
          background-color: transparent;
          border: none;
          color: $lf-white;
          font-size: 4rem;
          line-height: 1.2;
          text-indent: 5px;
          text-align: center;
          padding: 2rem;
          resize: none;
          width: 100%;
          white-space: pre-wrap;
          overflow: hidden;

          &.textarea-singlerow {
            overflow-y: hidden;
            white-space: nowrap;
          }

          &:focus,
          &:focus-visible {
            box-shadow: none;
            outline: none;
          }

          &::-ms-input-placeholder {
            color: currentColor;
          }

          &::-ms-input-placeholder {
            color: currentColor;
          }

          &::placeholder {
            color: currentColor;
          }
        }
      }
    }

    .editor-form {
      .form-group {
        .form-label {
          margin-bottom: $r2;
        }
      }
    }

    .editor-form-submit {
      .btn {
        margin-left: 0;
        margin-top: $r3;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .editor-symbol-preview {
      @include flex-center;
      background-color: $dark-gray;
      background-blend-mode: soft-light;
      height: 300px;
      position: relative;

      &-sm {
        height: 100px;
      }

      > div {
        margin: 0.5rem !important; // overwriting nextjs image component
      }

      .editor-symbol-preview-image {
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        height: 100%;

        > * {
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          margin: 0.5rem !important; // overwriting nextjs image component

          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .editor-symbol-text {
        font-family: $block-base;
        font-size: 4.5rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        position: absolute;
        user-select: none;
        white-space: nowrap;
      }

      &.symbol-mama-love {
        .editor-symbol-text {
          font-size: 7.5rem;
          text-transform: uppercase;
          padding-top: 0.5rem;
          padding-right: 2rem;
        }
      }

      &.symbol-sweetheart {
        .editor-symbol-text {
          font-size: 4rem;
          font-weight: bold;
          padding-right: 35px;
          text-transform: uppercase;
        }
      }
    }
  }

  &.customizer-module-text {
    .editor-options-list {
      align-items: start;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $r1;
      display: grid;

      .btn {
        margin-left: 0;
      }

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
