/***************************************
  Color Picker
***************************************/

$colorpicker-width: 36px;
$colorpicker-height: 36px;

.colorpicker-wrapper {
  align-items: flex-end;
  display: flex;
  margin-bottom: 1rem;

  .colorpicker-container {
    width: $colorpicker-width;
    height: $colorpicker-height;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;

    .colorpicker-input {
      border: 0;
      display: block;
      height: $colorpicker-height;
      opacity: 0;
      width: $colorpicker-width;
    }
  }
}
