/***************************************
  Customizer Modal
***************************************/

.fade {
  &.modal-symbol-backdrop {
    background-color: transparent;
  }
}

.modal {
  .modal-dialog {
    &.modal-symbol-dialog {
      width: 258px;
      margin: auto;
      height: 258px;

      .modal-content {
        border: 1px solid $lf-gray-muted;
        box-shadow: 0 0 8px rgba(18, 18, 18, 0.4);

        .modal-body {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .modal-content {
      border-radius: 0;
      border: none;

      .btn-group-modal {
        margin-top: 2.5rem;
        display: flex;
        gap: 0.625rem;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        .btn-common {
          margin-left: 0;
        }
      }
    }
  }
}
