/***************************************
  Landing/Login Page
***************************************/

// login

.login-wrapper {
  @include flex-center;
  background-color: $lf-light-gray;
  bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  .login-content {
    @include make-col(6);
    @include flex-center;
    background-color: $lf-white;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    flex-direction: column;
    padding: 3.75rem;

    main {
      margin-top: 4.25rem;
      width: 100%;

      .btn {
        &.btn-wide {
          width: 100%;
        }

        &.btn-login {
          @include flex-center;
          border: 2px solid $lf-light-gray;
          box-sizing: border-box;
          border-radius: 5px;
          color: $lf-gray;
          font-size: 1.125rem;
          font-weight: 600;
          text-align: left;

          &:hover,
          &:active {
            color: darken($lf-gray, 30%);
          }

          .ico-google {
            margin-right: 1rem;
          }
        }
      }
    }

    footer {
      margin-top: 4.25rem;
      margin-bottom: 2rem;

      p {
        color: $lf-gray;
        font-weight: 600;
        margin-bottom: 0;
      }

      a {
        color: $sporange;
      }
    }
  }
}
