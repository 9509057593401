/***************************************
  Upload DropZone
***************************************/

.upload-dropzone {
  flex-direction: column;
  display: flex;
  position: relative;

  .upload-dropzone-label {
    color: $gray-dark;
    font-size: 1.2rem;
  }

  .upload-dropzone-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    // height: 80px;
    width: 80px;

    .upload-dropzone-spacer {
      height: 80px;
      width: 80px;
      position: relative;
    }

    .upload-dropzone-inner {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .invalid-feedback {
        text-align: center;
        line-height: 1.5;
      }
    }

    // polaris changes

    .Polaris-DropZone {
      width: 80px;

      @at-root .perspective-draggable {
        .Polaris-Thumbnail {
          img {
            background-color: $lf-gray;
          }
        }
      }

      &--sizeSmall {
        align-items: flex-start;
        min-height: auto;
      }

      &--hasError {
        .upload-dropzone-spacer {
          &:after {
            border-color: $critical-red;
          }
        }

        .Polaris-Thumbnail {
          border-color: $critical-red;
        }
      }

      &--hasOutline {
        &:not(.Polaris-DropZone--focused) {
          &:after {
            display: none;
          }
        }

        &:not(.Polaris-DropZone--isDisabled) {
          &:hover {
            background-color: transparent;
          }
        }

        .upload-dropzone-spacer {
          &:hover {
            background-color: rgba(246, 246, 247, 1);
          }

          &:after {
            content: '';
            position: absolute;
            z-index: 29;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 0.2rem dashed rgba(140, 145, 150, 1);
            border-radius: 0.4rem;
            pointer-events: none;
          }
        }
      }

      .Polaris-DropZone__Container {
        position: static;

        &:after {
          display: none;
        }
      }
    }

    .Polaris-Thumbnail {
      padding: 0;
      margin: 3px auto;
      width: 80px;
      height: 80px;

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .upload-dropzone-label,
  .upload-dropzone-upload-label {
    align-items: center;
    display: flex;
    font-size: 1rem;
    justify-content: center;
  }

  .upload-dropzone-upload-label {
    color: $blue-dark;
  }

  .upload-dropzone-ico {
    margin-left: 0.5rem;
  }
}
