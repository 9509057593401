/***************************************
  Typography
***************************************/

@font-face {
  font-family: 'Neutra Text';
  src: url('/fonts/neutra/NeutraText-BookItalic.woff2') format('woff2'),
    url('/fonts/neutra/NeutraText-BookItalic.woff') format('woff'),
    url('/fonts/neutra/NeutraText-BookItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neutra Text';
  src: url('/fonts/neutra/NeutraText-Book.woff2') format('woff2'),
    url('/fonts/neutra/NeutraText-Book.woff') format('woff'),
    url('/fonts/neutra/NeutraText-Book.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neutra Text';
  src: url('/fonts/neutra/NeutraText-BoldItalic.woff2') format('woff2'),
    url('/fonts/neutra/NeutraText-BoldItalic.woff') format('woff'),
    url('/fonts/neutra/NeutraText-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neutra Text';
  src: url('/fonts/neutra/NeutraText-Bold.woff2') format('woff2'),
    url('/fonts/neutra/NeutraText-Bold.woff') format('woff'),
    url('/fonts/neutra/NeutraText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Block Base';
  src: url('/fonts/block-base/Block-Regular.woff2') format('woff2'),
    url('/fonts/block-base/Block-Regular.woff') format('woff'),
    url('/fonts/block-base/Block-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$neutra: 'Neutra Text', Helvetica, Arial, sans-serif;
$block-base: 'Block Base', 'Georgia', Times, serif;

body,
button,
input,
select,
textarea {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: $neutra;
}

.customizer-main {
  font-family: $neutra;

  .title,
  .font-neutra {
    font-family: $neutra;
  }

  .font-block {
    font-family: $block-base;
  }
}
