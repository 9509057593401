/***************************************
  Base
***************************************/

// vertical spacing

$r0: 0.3125rem;
$r1: $r0 * 2;
$r2: $r0 * 3;
$r3: $r0 * 4;
$r4: $r0 * 5;
$r5: $r0 * 6;
$r6: $r5 + 0.625;
$r7: $r6 + 0.625;
$r8: $r7 + 0.625;
$r9: $r8 + 0.625;

$spacing: $r0, $r1, $r2, $r3, $r4, $r5, $r6, $r7, $r8, $r9;

// cmt is a namespaced mt class
// from bootstrap (margin top)

@for $i from 0 to length($spacing) {
  .cmt-#{$i} {
    margin-top: nth($spacing, $i + 1);
  }
}

.flex-basis-0 {
  flex-basis: 0;
}

.align-items-center {
  align-items: center;
}

.customizer-alert {
  flex-direction: column;
}

.customizer-spinner {
  align-items: center;
  color: $lf-black;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

.viewport-center {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
