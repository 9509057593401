/***************************************
  Card Item
***************************************/

.card-item-wrapper {
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }
}

.card-item {
  align-items: center;
  display: flex;
  padding: 2rem 0 4rem;
  width: 100%;

  &.card-item-b-top {
    padding-top: 5rem;
    border-top: 1px solid $border-color;

    &:first-child {
      border-top: none;
    }
  }
}
