/***************************************
  Loading Spinner
***************************************/

.loading-spinner {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 2rem;
}
