/***************************************
  Base Styles
***************************************/

.section-divider {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.icon {
  &.icon-upload {
    margin-left: 0.5rem;
    width: 12px;
  }
}

.row {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    @include media-breakpoint-up(lg) {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .col-lg-5-5 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @include media-breakpoint-up(lg) {
      flex: 0 0 45.8%;
      max-width: 45.8%;
    }
  }
}

.action-toggle-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  .action-toggle-rotate {
    transition: transform 0.3s ease-out;
  }

  &.action-toggle-active {
    .action-toggle-rotate {
      transform: rotate(180deg);
    }
  }
}

.action-btn-pull-lg-4 {
  @include media-breakpoint-up(lg) {
    margin-left: -4rem;
  }
}

.action-btn-pull-lg-5-5 {
  @include media-breakpoint-up(lg) {
    margin-left: -5.5rem;
  }
}

.gap-2 {
  gap: 2rem;
}

.mt-5-5 {
  margin-top: 2.8rem;
}

.mb-2-2 {
  margin-bottom: 0.7rem;
}

.section-title {
  &.section-uppercase {
    text-transform: uppercase;
  }

  .Polaris-Heading {
    font-size: inherit;
  }
}

.uppercase {
  text-transform: uppercase;
}

.customizer-landing {
  .Polaris-Button ~ .Polaris-Button {
    margin-left: 1rem;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.flex-center {
  @include flex-center;
}
