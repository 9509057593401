/***************************************
  Customizer Footer
***************************************/

.customizer-footer {
  align-items: center;
  background-color: $peach;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: $r1;
  margin-top: $r8;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 10;

  @include media-breakpoint-up(md) {
    position: sticky;
    bottom: -1px;
    z-index: 10;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
  }

  .customizer-form-actions {
    margin-top: $r1;
    display: flex;
    width: 100%;

    @include media-breakpoint-up(md) {
      @include make-col(5);
      margin-top: 0;
      padding-left: $r4;
      padding-right: $r2;
    }

    > .btn {
      flex-grow: 1;
      flex-basis: 0;
      max-height: 50px;

      &.btn-common {
        &:not(.btn-common-inverse) {
          &:hover {
            background-color: $peach;
          }
        }
      }
    }
  }
}
