/***************************************
  Customizer Main
***************************************/

html,
body,
#__next {
  height: 100%;
}

body {
  &.customizer-pane-active {
    @include media-breakpoint-down(md) {
      overflow: hidden;
      padding-right: 15px;
    }
  }
}

.customizer-frame {
  display: flex;
  flex-direction: column;
  font-family: $neutra;
  height: 100%;

  .customizer-main {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;

    .customizer-canvas {
      position: relative;
      z-index: 6;

      &:not(.customizer-full) {
        @include media-breakpoint-up(lg) {
          @include make-col(7);
          box-shadow: 0 -4px 9px 3px rgba(0, 0, 0, 0.1);
        }
      }

      &.customizer-full {
        width: 100%;
      }

      .customizer-header {
        @include flex-center;
        box-shadow: 0 1px 0 1px $lf-gray-light;
        padding: 0.625rem 1.625rem;

        @include media-breakpoint-up(lg) {
          display: none;
        }

        .customizer-logo {
          margin-left: auto;
        }

        .btn-action-close-mobile {
          margin-left: auto;
        }
      }

      .customizer-product-area {
        position: relative;
        padding: 4rem 1.625rem 0;

        @include media-breakpoint-up(lg) {
          padding-top: 6rem;
        }

        .customizer-product {
          .customizer-product-perspectives {
            position: relative;

            .customizer-product-perspective {
              display: none;

              &.perspective-active {
                display: block;
              }

              .customizer-dropzone-overlay {
                filter: invert(54%) sepia(5%) saturate(0%) hue-rotate(283deg) brightness(100%) contrast(92%);
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity 0.2s ease;

                &.customizer-dropzone-overlay-active {
                  opacity: 0.5;

                  transition: all 0.3s ease;

                  &.customizer-dropzone-invalid {
                    // error state styles
                    zoom: 1;
                  }
                }
              }

              img {
                display: block;
                pointer-events: none;
                user-select: none;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .customizer-canvas-options {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: $r1;

        @include media-breakpoint-up(md) {
          margin-top: $r5;
        }

        @include media-breakpoint-up(lg) {
          margin-top: $r3;
        }
      }
    }

    .customizer-perspectives-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        &.active-perspective {
          .btn-perspective {
            color: $lf-black;
            font-weight: bold;
          }
        }

        &:not(:first-child) {
          border-left: 1px solid $dark-gray;
          padding-left: 1.25rem;
          margin-left: 1.25rem;
        }

        .btn-perspective {
          color: $dark-gray;
          font-size: 1rem;

          @include media-breakpoint-up(sm) {
            font-size: 1.25rem;
          }

          .btn-perspective-price {
            display: block;
            font-weight: normal;
            line-height: 1;
          }
        }
      }
    }

    .form-control {
      font-size: 1.4rem;
      line-height: 2.4rem;
      border-color: $lf-black;
      border-radius: 0;
      width: 100%;

      &:disabled,
      &:read-only,
      &.form-control-disabled-light {
        color: $lf-muted-gray;
        background-color: rgba(250, 251, 251, 1);
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(69, 143, 255, 1);
      }
    }

    .customizer-options-mobile {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 0 1rem;
      margin-top: $r3;

      @include media-breakpoint-up(md) {
        margin-top: $r5;
      }

      .customizer-options-header {
        border-top: 1px solid $lf-gray-muted;
        padding-top: $r3;
        margin-top: $r3;

        .customizer-options-title {
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      .customizer-options {
        margin-top: $r1;
        display: flex;

        .btn {
          font-size: 1.125rem;
          padding: 1.25rem;
        }
      }
    }
  }

  .customizer-logo {
    width: 170px;

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .customizer-pane-footer {
    border-top: 1px solid $lf-gray-muted;
    padding-top: $r3;
  }

  .customizer-price {
    @include flex-center;
    margin-bottom: $r2;
    padding-left: $r2;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    .customizer-price-total {
      font-size: 1.25rem;
      display: flex;
      letter-spacing: -0.5px;

      span {
        margin-left: $r1;
      }

      @include media-breakpoint-up(md) {
        font-size: 2rem;

        span {
          margin-left: $r6;
        }
      }
    }
  }

  .customizer-alert {
    @include flex-center;
    border-radius: 0.125rem;
    position: relative;
    padding: 0.625rem;
    margin: 0.5rem 1.25rem 0;

    &:first-child {
      margin-top: 1.25rem;
    }

    p {
      &:only-child,
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.customizer-alert-danger {
      background-color: $red-tint;
      color: $red-higher;
    }
  }

  .handstiched-in-nyc-stamp {
    position: absolute;
    top: 0;
    z-index: 10;
    left: 4%;
    width: 110px;
    rotate: -15deg;
    svg {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      width: 150px;
      top: 25px;
    }

    @include media-breakpoint-up(lg) {
      left: 4%;
      top: 55px;
      width: auto;
    }
  }
}
