/***************************************
  Upload Input
***************************************/

$label-color: #4680d1;

.inline-upload-input {
  color: $label-color;

  > .Polaris-DropZone {
    min-height: 0;
    height: auto;

    .Polaris-DropZone__Container {
      position: static;
    }
  }
}
